import request from '@/utils/request'

// 个人信息
export function info() {
	return request({
		url: '/info',
		method: 'get'
	})
}
// 个人信息
export function getToken(params) {
    return request({
        url: '/auth',
        method: 'post',
		params
    })
}
// 登录方法
export function addressLst() {
	return request({
		url: '/address/lst',
		method: 'get'
	})
}

export function areaAdd(data) {
	return request({
		url: '/address/add',
		method: 'post',
		data
	})
}

export function areaEdit(data) {
	return request({
		url: '/address/edit',
		method: 'post',
		data
	})
}

export function areaDel(data) {
	return request({
		url: '/address/del',
		method: 'delete',
		data
	})
}

export function areaInfo(params) {
	return request({
		url: '/address/info',
		method: 'get',
		params
	})
}

export function modifyPassword(data) {
	return request({
		url: '/password/reset',
		method: 'post',
		data: data
	})
}

// 获取收藏列表
export function collectList(params) {
	return request({
		url: '/collect/lst',
		method: 'get',
		params
	})
}
// 删除收藏
export function delList(params) {
	return request({
		url: '/collect/del',
		method: 'delete',
		params
	})
}
// 获取浏览列表
export function historyList(params) {
	return request({
		url: '/history/lst',
		method: 'get',
		params
	})
}
// 删除浏览列表
export function historyDel(params) {
	return request({
		url: '/history/del',
		method: 'delete',
		params
	})
}
// 关注列表
export function concernList(params) {
	return request({
		url: '/follow/lst',
		method: 'get',
		params
	})
}
// 删除关注列表
export function concernDel(params) {
	return request({
		url: '/follow/del',
		method: 'delete',
		params
	})
}

//获取用户地址
export function getUserAddressList() {
	return request({
		url: '/address/lst',
		method: 'get',
		isLogin: true,
	})
}
//获取用户地址详情
export function getUserAddressDetail(params) {
	return request({
		url: '/address/info',
		method: 'get',
		params,
		isLogin: true,
	})
}
//编辑用户地址
export function addUserAddressList(data) {
	return request({
		url: '/address/edit',
		method: 'post',
		data: data,
		isLogin: true,
	})
}
//删除用户地址
export function delUserAddress(data) {
	return request({
		url: '/address/del',
		method: 'DELETE',
		data: data,
		isLogin: true,
	})
}

//图片上传
export function uploadImage(data) {
	return request({
		url: '/upload/image/one',
		method: 'POST',
		data: data,
		isLogin: true,
		// headers: {
		//       'content-type': 'multipart/form-data',
		//     },
	})
}
//更新用户信息
export function updateUser(data) {
	return request({
		url: '/user/changeInfo',
		method: 'post',
		data: data,
		isLogin: true,
	})
}

// 优惠列表
export function discountslist(params) {
	return request({
		url: '/coupon/lst',
		method: 'get',
		params: params,
		isLogin: true,
	})
}
// 优惠详情
export function discountDetails(params) {
	return request({
		url: '/coupon/info',
		method: 'get',
		params: params,
		isLogin: true,
	})
}

// 领取优惠
export function getDiscount(params) {
	return request({
		url: '/coupon/receive',
		method: 'post',
		params: params,
		isLogin: true,
	})
}
// 优惠轮播图
export function discountImg(params) {
	return request({
		url: '/ad/lst',
		method: 'get',
		params: params,
		isLogin: true,

	})
}

// 用户优惠券列表
export function getUserCoupon(params) {
	return request({
		url: '/userCoupon/lst',
		method: 'get',
		params: params,
		isLogin: true,

	})
}

// 消息列表
export function getChatList(params) {
	return request({
		url: '/chat/groupList',
		method: 'get',
		params: params,
		isLogin: true,

	})
}
// 创建会话
export function createChat(params) {
	return request({
		url: '/chat/add',
		method: 'post',
		params: params,
		isLogin: true,

	})
}
// 会话消息历史记录
export function getChatHistory(params) {
	return request({
		url: '/chat/history',
		method: 'get',
		params: params,
		isLogin: true,

	})
}
