const state = {
  footer: true,
  city:'',
  latAndLng:''
}

const mutations = {
  SHOW_FOOTER(state) {
    state.footer = true
  },
  HIDE_FOOTER(state) {
    state.footer = false
  },
  LatAndLng(state,value){
	 state.latAndLng = value 
  },
  LOCATION_CITY(state,city){
	   state.city = city
	  
	  }
}

const actions = {
 setdynamicrouter({commit},data){
        return new Promise(resolve => {
            commit('LOCATION_CITY',data)
            resolve()
        })
    },
}

export default {
  state,
  mutations,
  actions
}
