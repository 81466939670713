<template>
	<van-tabbar v-model="active" active-color="#FC6228" :route="true">
		<van-tabbar-item v-for="(tab, key) in tabs" :key="key" :name="tab.name" :to="tab.to" :icon="tab.icon">
			{{ tab.title }}
		</van-tabbar-item>
	</van-tabbar>
</template>
<script>
	export default {
		data() {
			return {
				// <van-icon name="manager" />
				active: 'Home',
				tabs: [{
						'name': 'Inheritor',
						'to': '/h5',
						'icon': 'wap-home',
						'title': '首页'
					},

					{
						'name': 'Works',
						'to': '/h5/shopPage',
						'icon': 'shop',
						'title': '店铺'
					},

					{
						'name': 'OrderPage',
						'to': '/h5/orderPage',
						'icon': 'todo-list',
						'title': '订单'
					},
					{
						'name': 'Mine',
						'to': '/h5/mine',
						'icon': 'manager',
						'title': '我的'
					}
				]
			}
		}
	}
</script>

<style lang="less" scoped>
	.van-tabbar-item{
		color: #AEADAD;
	}
</style>
