import request from '@/utils/request'

// 轮播图
export function getSlideshow(params) {
    return request({
        url: '/ad/lst',
        method: 'get',
        isLogin: false,
		params
    })
}
// 购物精选
export function getShopRecom(params) {
    return request({
        url: '/home/shopRecom',
        method: 'get',
        isLogin: false,
		params
    })
}
// 游玩推荐
export function getTravelRecom(params) {
    return request({
        url: '/home/travelRecom',
        method: 'get',
        isLogin: false,
		params
    })
}
// 美食推荐
export function getFoodRecom(params) {
    return request({
        url: '/home/foodRecom',
        method: 'get',
        isLogin: false,
		params
    })
}
// 精品酒店
export function getHotelRecom(params) {
    return request({
        url: '/home/hotelRecom',
        method: 'get',
        isLogin: false,
		params
    })
}
// 商品列表
export function getProductLst(params) {
    return request({
        url: '/product/lst',
        method: 'get',
        isLogin: false,
		params
    })
}
// 商品列表详情
export function getProductDetails(params) {
    return request({
        url: '/product/info',
        method: 'get',
        isLogin: true,
		params
    })
}
// 添加收藏
export function getDddCollect(params) {
    return request({
        url: '/collect/add',
        method: 'post',
        isLogin: true,
		params
    })
}
// 删除收藏
export function delList(params) {
    return request({
        url: '/collect/del',
        method: 'delete',
		params
    })
}
// 分类
export function getCate(params) {
    return request({
        url: '/cate/lst',
        method: 'get',
        isLogin: false,
		params
    })
}

// 文旅
export function getHotel(params) {
    return request({
        url: '/hotel/index',
        method: 'get',
        isLogin: false,
		params
    })
}
// 文旅景区
export function getScenic(params) {
    return request({
        url: '/travel/hot',
        method: 'get',
        isLogin: false,
		params
    })
}
// 文旅酒店
export function getHotelList(params) {
    return request({
        url: '/hotel/lst',
        method: 'get',
        isLogin: false,
		params
    })
}




//获取首页分类
export function getIndexCategory() {
    return request({
        url: '/master/getOrgAndCategory',
        method: 'get',
        isLogin: false,
    })
}
//获取作品列表
export function getWorksList(params) {
    return request({
        url: '/master/goods',
        method: 'post',
        isLogin: false,
		params
    })
}
//获取作品分类
export function getWorksCategory(params) {
    return request({
        url: '/master/goodsCategory',
        method: 'post',
        isLogin: false,
		params
    })
}
//获取作品详情
export function getWorksDetail(params) {
    return request({
        url: '/master/goodsDetail',
        method: 'post',
        isLogin: false,
		params
    })
}

//获取禅乐列表
export function getMusicList(params) {
    return request({
        url: '/master/audio',
        method: 'post',
        isLogin: false,
		params
    })
}
//获取禅乐分类
export function getMusicCategory(params) {
    return request({
        url: '/master/audioCategory',
        method: 'post',
        isLogin: false,
		params
    })
}
//获取禅乐详情
export function getMusicDetail(params) {
    return request({
        url: '/master/audioDetail',
        method: 'post',
        isLogin: false,
		params
    })
}
//获取首页列表
export function getIndexList(params) {
    return request({
        url: '/master',
        method: 'post',
        isLogin: false,
		params
    })
}

//获取首页商品列表
export function getGoodsList(params) {
    return request({
        url: '/product/recom',
        method: 'get',
		params:params,
        isLogin: false,
    })
}
//获取首页轮播图
export function getIndexImage(params) {
    return request({
        url: '/ad/lst',
        method: 'get',
		params:params,
        isLogin: false,
    })
}
//获取首页城市
export function getIndexCity(params) {
    return request({
        url: '/map/address',
        method: 'post',
		params,
        isLogin: false,
    })
}

// 搜索
export function getSearch(data) {
    return request({
        url: '/home/search',
        method: 'get',
        params:data,
		isLogin: true,
    })
}
// 联想搜索
export function getSearchTip(data) {
    return request({
        url: '/home/searchTip',
        method: 'get',
        params:data,
		isLogin: true,
    })
}

// 联想搜索
export function shopList() {
    return request({
        url: '/restDetail.json',
        method: 'get',
    })
}
