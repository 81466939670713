<template>

	<router-view v-slot="{ Component }" v-if="isRouterAlive">
		<keep-alive>
			<component :is="Component" v-if="$route.meta.keepAlive" />
		</keep-alive>
		<component :is="Component" v-if="!$route.meta.keepAlive" />
	</router-view>

	<!-- <router-view /> -->
	<!-- <Footer v-show="footer" /> -->
	<footerBus v-show="footer"></footerBus>
	<!--    <float-button v-show="!footer" />-->

</template>
<script>
	import {

		getIndexCity

	} from '@/api/index'
	import {
	
		getToken
	
	} from '@/api/user'
	import footerBus from '@/components/footerBus.vue'
	// import FloatButton from "@/components/FloatButton"
	import {
		mapGetters
	} from 'vuex'
	import upsdk from 'upsdk-vue'
	import {
		config
	} from "@/api/login";
	import {
		isUnionPay
	} from '@/utils/tools'
	import WebSocket from '@/utils/socket'
	import mitt from 'mitt'

	export default {
		components: {
			footerBus
		},
		computed: {
			...mapGetters([
				'footer'
			])
		},
		provide() { //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
			return {
				reload: this.reload
			}
		},

		data() {
			return {
				ws: null,
				mitt: null,
				isRouterAlive: true
			}
		},
		mounted() {
			// "lng": "114.649651",
			// "lat": "33.620358",
			var that = this
			var value = {}
			// var code = localStorage.getItem('code')
			// console.log(code + 'app里面的code')
			// if(code){
			// 	getToken(code).then(res=>{
			// 		this.$store.commit('SET_TOKEN', res.data.token)
			// 	})
			// }
			var res = {}
			res.latitude = 34.757977
			res.longitude = 113.665413
			
			console.log(typeof(res) + '经纬度')
			that.$store.commit('LatAndLng', res)
			
			value.lat = res.latitude
			value.lng = res.longitude
		
			getIndexCity(value).then((r) => {
				
				that.$store.dispatch('setdynamicrouter', r.data)
			
			})
			// 云闪付环境配置
			if (isUnionPay()) {
				config({
					url: window.location.href
				}).then((res) => {
					upsdk.config({
						appId: res.data.appId,
						timestamp: res.data.timestamp,
						nonceStr: res.data.nonceStr,
						signature: res.data.signature,
						debug: res.data.debug
					})
				})
				// 标题颜色
				upsdk.ready(function() {
					upsdk.setTitleStyle({
						navBackgroundColor: '0x8FFFFFFF',
						appletStyle: 'black',
						backBtnVisible: 1
					})
					//经纬度
					upsdk.getLocationGps({
						success: function(res) {
							console.log(typeof(res) + '经纬度')
							that.$store.commit('LatAndLng', JSON.parse(res))
							var value = {}
							value.lat = JSON.parse(res).latitude
							value.lng = JSON.parse(res).longitude
							console.log(JSON.stringify(value) + '这是value')
							getIndexCity(value).then((r) => {
								console.log(JSON.stringify(r) + '接口返回')
								that.$store.dispatch('setdynamicrouter', r.data)

							})

						},
						fail: function() {}
					})
					//首页城市
					// upsdk.getLocationCity({
					// 	success: function(cityCd) {
					//           getIndexCity({code:cityCd}).then((res)=>{
					// 			console.log(res + '接口返回')
					// 			 that.$store.commit('LOCATION_CITY', res.data)

					// 		 })
					// 	}
					// })
				});


			}
			// this.ws = new WebSocket()
			// this.mitt = mitt()
			// this.ws.channel(this.mitt)
			// this.mitt.on('message', data => {
			//   this.messagePush(data)
			// })
		},
		methods: {
			reload() {
				this.isRouterAlive = false; //先关闭，
				this.$nextTick(function() {
					this.isRouterAlive = true; //再打开
				})
			},
			messagePush(data) {
				console.log("收到消息", data)
			}
		}
	}
</script>
<style>
	.van-swipe-cell__left,
	.van-swipe-cell__right {
		background-color: none !important;
	}

	.van-swipe-cell__wrapper {
		display: flex;
	}

	.van-swipe-cell__left,
	.van-swipe-cell__right {
		top: 20% !important;
		height: 0 !important;
	}

	#app {
		background: #f6f6f6;

	}

	html,
	body,
	#app {
		height: 100%;
	}
</style>
