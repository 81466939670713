import request from '@/utils/request'

// 登录方法 ok
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    isLogin: false,
    data: data,
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 授权登录
export function auth(params) {
  return request({
    url: '/auth',
    method: 'post',
    isLogin: false,
    params
  })
}

// 初始化
export function config(data) {
  return request({
    url: '/config',
    method: 'post',
    isLogin: false,
    data: data
  })
}
