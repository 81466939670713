import axios from 'axios'
import { Toast } from 'vant'
import config from '@/utils/config'
import { getToken } from '@/utils/token'
import store from '@/store'
	import {
		isUnionPay
	} from '@/utils/tools'
// 加入Content-Type
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// token
// const token = getToken()
const token =localStorage.getItem('token')
console.log('这是请求拦截' + token)
// 创建axios实例
const service = axios.create({
  baseURL: config.server,
  // 10s超时
  timeout: 10000,
  // 默认需要登录
  isLogin: true
})

// 拦截请求
service.interceptors.request.use(
  config => {
    if (config.isLogin) {
      if (token) {
        config.headers['Authorization'] = token
      } 
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// 拦截响应
service.interceptors.response.use(response => {
  const res = response.data
   // return res
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 200) {
    Toast({
      message: res.msg || 'Error',
      type: 'fail',
      duration: 5 * 1000
    })
    // 登录过期失效
    if (res.code === 401 || res.code === 402) {
      // 先从本地退出再登录
	
	  if(isUnionPay()){
		  localStorage.clear()
		  location.href = 'http://unionshop.esw235.cn/h5/upsdkLogin.html'
	  }else{
		  store.dispatch('LoginOut').then(() => {
		    store.dispatch('ToLogin', window.location.href)
		  })
	  }
    
    }
    // return Promise.reject(new Error(res.msg || 'Error'))
	 return res
  } else {
    return res
  }
},
error => {
  console.log('err' + error) // for debug
  Toast({
    message: error.msg,
    type: 'fail',
    duration: 5 * 1000
  })
  return Promise.reject(error)
},
error => {
  console.log('err' + error)
  Toast({
    message: error.message,
    type: 'fail',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})

export default service
