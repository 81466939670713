import {
	createRouter,
	createWebHistory
} from 'vue-router'
import $store from '../store'
import {
	buildUrl
} from '@/utils/tools'

const routes = [{
		path: '/h5',
		name: 'Index',
		meta: {
			footer: true,
			title: '首页',
			isLogin: false
		},
		component: () => import('../views/home/index.vue')

	},
	{
			path: '/h5/upsdkLogin',
			name: 'UpsdkLogin',
			meta: {
				footer: false,
				title: '授权登录',
				isLogin: false
			},
			component: () => import('../views/upsdkLogin.vue')
	
		},
	{
			path: '/h5/commDetail',
			name: 'CommDetail',
			meta: {
				footer: false,
				title: '商品详情',
				isLogin: true
			},
			component: () => import('../views/home/commDetail.vue')
	
		},
	{

		path: '/h5/foods/index',
		name: 'FoodsIndex',
		meta: {
			footer: true,
			title: '品味河南',
			isLogin: true
		},
		component: () => import('../views/secondIndex/foods/index.vue')
	},
	// foodslist
	{
	
		path: '/h5/foodslist',
		name: 'Foodslist',
		meta: {
			footer: false,
			title: '品味河南',
			isLogin: true
		},
		component: () => import('../views/secondIndex/foods/foodslist.vue')
	},
	{
		path: '/h5/play/index',
		name: 'HotelIndex',
		meta: {
			footer: true,
			title: '玩在河南',
			isLogin: true
		},
		component: () => import('../views/secondIndex/play/index.vue')
	},
	{
		path: '/h5/play/scenicSpotRank',
		name: 'ScenicSpotRank',
		meta: {
			footer: false,
			title: '热门景区排名',
			isLogin: true
		},
		component: () => import('../views/secondIndex/play/scenicSpotRank.vue')
	},
	{
		path: '/h5/play/hotHotel',
		name: 'HotHotel',
		meta: {
			footer: false,
			title: '酒店列表',
			isLogin: true
		},
		component: () => import('../views/secondIndex/play/hotHotel.vue')
	},
	{
		path: '/h5/moreTravel',
		name: 'MoreTravel',
		meta: {
			footer: false,
			title: '游玩推荐',
			isLogin: true
		},
		component: () => import('../views/home/moreTravel.vue')
	},
	{
		path: '/h5/shoppingChoicen',
		name: 'ShoppingChoicen',
		meta: {
			footer: false,
			title: '购物精选',
			isLogin: true
		},
		component: () => import('../views/home/shoppingChoicen.vue')
	},
	{
		path: '/h5/shopping/index',
		name: 'ShoppingIndex',
		meta: {
			footer: true,
			title: '爱购河南',
			isLogin: true
		},
		component: () => import('../views/secondIndex/shopping/index.vue')
	},
	{
	
		path: '/h5/shoppinglist',
		name: 'Shoppinglist',
		meta: {
			footer: false,
			title: '爱购河南',
			isLogin: true
		},
		component: () => import('../views/secondIndex/shopping/shoppinglist.vue')
	},
	{
		path: '/h5/secnicDetails',
		name: 'SecnicDetails',
		meta: {
			footer: false,
			title: '景区详情',
			isLogin: true
		},
		component: () => import('../views/detail/secnicDetails.vue')
	},
	// secnicOrder
	{
		path: '/h5/secnicOrder',
		name: 'SecnicOrder',
		meta: {
			footer: false,
			title: '提交订单',
			isLogin: true
		},
		component: () => import('../views/detail/secnicOrder.vue')
	},
	{
		path: '/h5/detail',
		name: 'InheritorDetail',
		meta: {
			footer: true,
			title: '传承人详情',
			isLogin: true
		},
		component: () => import('../views/detail/detail.vue')
	},
	{
		path: '/h5/address',
		name: 'Address',
		meta: {
			footer: false,
			title: '选择城市',
			isLogin: false
		},
		component: () => import('../views/home/address.vue')
	},

	{
		path: '/h5/shopPage',
		name: 'shopPage',
		meta: {
			footer: true,
			title: '店铺',
			isLogin: false
		},
		component: () => import('../views/home/shopPage.vue')
	},
	{
		path: '/h5/shopList',
		name: 'ShopList',
		meta: {
			footer: false,
			title: '商品列表',
			isLogin: true
		},
		component: () => import('../views/home/shopList.vue')
	},
	{
		path: '/h5/map',
		name: 'Map',
		meta: {
			footer: false,
			title: '地图',
			isLogin: true
		},
		component: () => import('../views/home/map.vue')
	},
	{
		path: '/h5/foodDetail',
		name: 'foodDetail',
		meta: {
			footer: false,
			title: '店铺详情',
			isLogin: true
		},
		component: () => import('../views/detail/foodDetail.vue')
	},
	{
		path: '/h5/hotelDetails',
		name: 'HotelDetails',
		meta: {
			footer: false,
			title: '酒店详情',
			isLogin: true
		},
		component: () => import('../views/detail/hotelDetails.vue')
	},
	{
		path: '/h5/hoteSumit',
		name: 'HoteSumit',
		meta: {
			footer: false,
			title: '酒店提交订单',
			isLogin: true
		},
		component: () => import('../views/detail/hoteSumit.vue')
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			footer: false,
			title: '登录'
		},
		component: () => import('../views/Login.vue')
	},
	{
		path: '/h5/orderPage',
		name: 'OrderPage',
		meta: {
			footer: true,
			title: '订单'
		},
		component: () => import('../views/order/orderPage.vue')
	},
	{
		path: '/h5/onceOeder',
		name: 'OnceOeder',
		meta: {
			footer: false,
			title: '立即订单'
		},
		component: () => import('../views/order/onceOeder.vue')
	},
	{
		path: '/h5/atOrder',
		name: 'AtOrder',
		meta: {
			footer: false,
			title: '立即下单'
		},
		component: () => import('../views/order/atOrder.vue')
	},
	{
		path: '/h5/orderDetail',
		name: 'orderDetail',
		meta: {
			footer: false,
			title: '订单详情'
		},
		component: () => import('../views/order/orderDetail.vue')
	},
	// toQrcode
	{
		path: '/h5/toQrcode',
		name: 'ToQrcode',
		meta: {
			footer: false,
			title: '订单详情'
		},
		component: () => import('../views/order/toQrcode.vue')
	},
	{
		path: '/goEvaluate',
		name: 'GoEvaluate',
		meta: {
			footer: false,
			title: '评价'
		},
		component: () => import('../views/order/goEvaluate.vue')
	},
	{
		path: '/h5/mine',
		name: 'Mine',
		meta: {
			footer: true,
			title: '我的'
		},
		component: () => import('../views/mine/mine.vue')
	},
	{
		path: '/h5/updateUser',
		name: 'UpdateUser',
		meta: {
			footer: false,
			title: '修改'
		},
		component: () => import('../views/mine/updateUser.vue')
	},
	// collectPage
	{
		path: '/h5/collectPage',
		name: 'CollectPage',
		meta: {
			footer: false,
			title: '收藏'
		},
		component: () => import('../views/mine/collectPage.vue')
	},
	{
		path: '/h5/footprintPage',
		name: 'FootprintPage',
		meta: {
			footer: false,
			title: '足迹'
		},
		component: () => import('../views/mine/footprintPage.vue')
	},
	{
		path: '/h5/concernPage',
		name: 'ConcernPage',
		meta: {
			footer: false,
			title: '关注'
		},
		component: () => import('../views/mine/concernPage.vue')
	},
	// shoppingAddress
	{
		path: '/h5/couponPage',
		name: 'CouponPage',
		meta: {
			footer: false,
			title: '卡券'
		},
		component: () => import('../views/mine/couponPage.vue')
	},
	{
		path: '/h5/shoppingAddress',
		name: 'ShoppingAddress',
		meta: {
			footer: false,
			title: '收货地址'
		},
		component: () => import('../views/mine/shoppingAddress.vue')
	},
	{
		path: '/h5/addAddress',
		name: 'AddAddress',
		meta: {
			footer: false,
			title: '添加地址'
		},
		component: () => import('../views/mine/addAddress.vue')
	},
	{
		path: '/h5/contactUs',
		name: 'ContactUs',
		meta: {
			footer: false,
			title: '联系我们'
		},
		component: () => import('../views/mine/contactUs.vue')
	},
	{
		path: '/h5/AboutUs',
		name: 'AboutUs',
		meta: {
			footer: false,
			title: '关于我们'
		},
		component: () => import('../views/mine/AboutUs.vue')
	},,
	{
		path: '/h5/couponCentre',
		name: 'CouponCentre',
		meta: {
			footer: false,
			title: '领取中心'
		},
		component: () => import('../views/coupon/couponCentre.vue')
	},
	{
		path: '/h5/couponList',
		name: 'CouponList',
		meta: {
			footer: false,
			title: '优惠券列表'
		},
		component: () => import('../views/coupon/couponList.vue')
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, form, next) => {
	const {
		title,
		footer,
		isLogin
	} = to.meta
	// 是否需要登录
	console.log(form)
	var code = localStorage.getItem('code')
	if (isLogin === true && !code) {
		sessionStorage.setItem('historyPath',to.path)
		// location.href = 'http://unionshop.esw235.cn/h5/upsdkLogin.html'
		
		// $store.dispatch('ToLogin', buildUrl(to.href))
	}

	// 标题
	document.title = title
	// 底部导航是否显示
	footer === true ? $store.commit('SHOW_FOOTER') : $store.commit('HIDE_FOOTER')
	if (to.query.storeName) {
		console.log(to.query.storeName)
		document.title = to.query.storeName
		// next()
	}
	next()
})

export default router