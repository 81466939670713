// 接口域名
const APP_BASE_URL = 'http://unionshop.esw235.cn/'
// const APP_BASE_URL = 'https://ysf.leapy.cn/'
const APP_WS_URL = 'ws://182.254.133.82:2001/'

export default {
	imageUrl:APP_BASE_URL,
  server: APP_BASE_URL + 'api/',
  ws: APP_WS_URL,
  // appId
  appId: 'a8c117dd0d644622a1b26034b63aff55',
}
